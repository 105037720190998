<template>
  <div class="container">
    <Dialog
      :header="dictionary.login"
      :closable="false"
      :visible="true"
      class="login-dialog"
    >
      <div v-if="loading">
        <ProgressSpinner
          class="spinner flex items-center justify-center"
          strokeWidth="2"
          animationDuration="3s"
        />
      </div>
      <div v-else class="form-wrapper">
        <form @submit.prevent="onSubmit">
          <FloatLabel>
            <InputText id="username" v-model="username" />
            <label for="username">{{ dictionary.login_username }}</label>
          </FloatLabel>
          <FloatLabel>
            <Password
              v-model="password"
              id="password"
              :feedback="false"
              toggleMask
            />
            <label for="password">{{ dictionary.login_password }}</label>
            <small class="p-error">{{ errmsg }}</small>
          </FloatLabel>
          <div class="w-full flex justify-end">
            <Button
              type="submit"
              class="submit-btn"
              icon="pi pi-sign-in"
              :label="dictionary.login_btn"
            />
          </div>
        </form>
      </div>
    </Dialog>
    <Toast ref="toast" />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "../../../store/store.js";
import { useRouter } from "vue-router";
import Dialog from "primevue/dialog";
import Toast from "primevue/toast";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import { dictionary } from "../../dictionary.js";

const store = useStore();
const router = useRouter();
const toast = ref(null);
const loading = ref(false);
const password = ref("");
const username = ref("eld_user");
const errmsg = ref("");

const onSubmit = async (event) => {
  event.preventDefault();
  loading.value = true;
  try {
    const response = await fetch("https://eld-mapper-backend.api.prod.sit.sys.odj.cloud/admin/login", {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username.value,
        password: password.value,
      }),
    });

    if (response.ok) {
      setToast();
      errmsg.value = "";
      const timer = setTimeout(() => {
        loading.value = false;
        store.fetchData();
        router.push({ name: "dashboard" });
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      const message = await response.text();
      errmsg.value = message;
      console.error(message);
      loading.value = false;
    }
  } catch (error) {
    console.error(error);
  }
};

const setToast = () => {
  toast.value.add({
    severity: "success",
    summary: "Success",
    detail: dictionary.login_access,
    life: 2000,
  });
};
</script>

<style scoped>
.spinner {
  width: 50px;
  height: 50px;
}
</style>
