<template>
  <div class="training-wrapper p-4" v-if="mod1">
    <div class="row">
      <div class="w-1/5">
        <img
          :src="mod1.url"
          :alt="mod1?.short"
          class="w-full border-round custom:mt-6 xl:mt-0 mb-4"
        />
      </div>
      <div class="flex flex-col justify-start items-start">
        <span
          :class="[
            mod1.department.toLowerCase() + '-txt custom:text-base xl:text-xl',
            mod1 && mod2 && isCommon(mod1.short, mod2.short)
              ? 'common-element'
              : '',
          ]"
        >
          {{ mod1.short }}
        </span>
        <div>
          <span class="custom:text-xs xl:text-sm">{{
            dictionary.dataview_item3
          }}</span>
        </div>
        <span class="text-left custom:text-xs xl:text-sm">{{
          mod1.specification[0].objective
        }}</span>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4 mt-4">
      <div
        :class="[
          'mod-property col-span-2 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.department, mod2.department)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_department }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.department
        }}</span>
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.duration, mod2.duration)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_duration }}</small>
        <span class="text-left custom:text-sm xl:text-base"
          >{{ mod1.duration }}{{ dictionary.comparator_min }}</span
        >
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.type, mod2.type)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_type }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.type
        }}</span>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4 mt-4">
      <div
        :class="[
          'mod-property col-span-2 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.element, mod2.element)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_element }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.element
        }}</span>
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.target, mod2.target)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_target }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.target
        }}</span>
      </div>
      <div
        :class="[
          'mod-property col-span-1 p-2 flex flex-col justify-start',
          mod1 && mod2 && isCommon(mod1.employee, mod2.employee)
            ? 'common-element'
            : '',
        ]"
      >
        <small class="text-left">{{ dictionary.comparator_emplyee }}</small>
        <span class="text-left custom:text-sm xl:text-base">{{
          mod1.employee
        }}</span>
      </div>
    </div>
    <div class="flex flex-col justify-start mt-4">
      <span class="text-left mb-2 custom:text-sm xl:text-base">{{
        dictionary.comparator_topics
      }}</span>
      <ul class="grid grid-cols-4 gap-4">
        <li
          v-for="(spec, index) in mod1.specification"
          :key="index"
          :class="[
            'mod-property p-2 text-left flex items-center custom:text-sm xl:text-base custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
            mod1 &&
            mod2 &&
            findCommonValueInArray(spec.topic, mod2.specification.flatMap((spec) => spec.topic))
              ? 'common-element'
              : '',
          ]"
        >
          {{ spec.topic }}
        </li>
      </ul>
    </div>
    <div class="flex flex-col justify-start mt-4">
      <span class="text-left mb-2 custom:text-sm xl:text-base">{{
        dictionary.comparator_stopics
      }}</span>
      <ul class="grid grid-cols-4 gap-4">
        <li
          v-for="(item, index) in flatStopics"
          :key="index"
          :class="[
            'mod-property p-2 text-left flex items-center custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
            mod1 &&
            mod2 &&
            findCommonValueInArray(
              item.stopic,
              mod2.specification.flatMap((spec) => spec.stopic)
            )
              ? 'common-element'
              : '',
          ]"
        >
          {{ item.stopic }}
        </li>
      </ul>
    </div>

    <div class="flex flex-col justify-start mt-4">
      <span class="text-left mb-2 custom:text-sm xl:text-base">{{
        dictionary.comparator_tags
      }}</span>
      <ul class="text-left flex flex-row">
        <li
          v-for="(tag, index) in mod1.tag"
          :key="index"
          :class="[
            'tag rounded p-1 custom:mr-2 xl:mr-4 custom:text-xs xl:text-sm',
            mod1 && mod2 && findCommonValueInArray(tag, mod2.tag)
              ? 'common-element'
              : '',
          ]"
        >
          {{ tag }}
        </li>
      </ul>
    </div>
    <Divider />
    <div class="w-full flex flex-row">
      <router-link
        :to="{ name: 'module' }"
        @click="handleDisplayModule(mod1)"
        class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 custom:mr-3 xl:p-2 xl:mr-4 xl:text-base xl:h-auto xl:mt-0 rounded"
      >
        <i class="pi pi-map"></i>
        {{ dictionary.dataview_show }}
      </router-link>
      <Button
        icon="pi pi-times"
        class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 custom:mr-3 xl:p-2 xl:mr-4 xl:text-base xl:h-auto xl:mt-0 rounded"
        :label="dictionary.comparator_remove_btn"
        @click="removeModule"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";
import { useStore } from "../../../../store/store.js";
import { dictionary } from "../../../dictionary.js";
import Divider from "primevue/divider";
import Button from "primevue/button";

const store = useStore();
const props = defineProps({
  mod1: Object,
  mod2: Object,
  slot1: Boolean,
});
const mod1 = ref(props.mod1);
const mod2 = ref(props.mod2);

const flatStopics = ref([]);
const uniqueTopics = new Set();
flatStopics.value =
  props.mod1?.specification?.flatMap((spec) =>
    spec.stopic
      .map((stopic) => {
        const item = { ...spec, stopic };
        const key = JSON.stringify(item);
        if (!uniqueTopics.has(key)) {
          uniqueTopics.add(key);
          return item;
        }
        return null;
      })
      .filter((item) => item !== null)
  ) || [];

const emit = defineEmits(["update:mod1", "update:slot1"]);

const handleDisplayModule = (mod) => {
  store.setSelectedModule(mod);
};

const removeModule = () => {
  emit("update:mod1", null);
  emit("update:slot1", true);
  localStorage.removeItem("mod");
};

const isCommon = (value1, value2) => {
  return value1 === value2;
};

const findCommonValueInArray = (val, flatarr) => {
  return flatarr.includes(val);
};

watch(
  () => props.mod2,
  (newMod2) => {
    mod2.value = newMod2;
  }
);
</script>

<style scoped>
.training-wrapper {
  width: 100%;
  height: auto;
}
.row {
  width: 100%;
}
.mod-property {
  background-color: #1f2937;
}

.stopic-property {
  flex: 1 1 auto;
}

.common-element {
  background-color: #b4e68c;
  color: #1f2937;
}
.tag {
  border: 1px solid #c4e9ff;
}

.grid {
  display: grid;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.gap-4 {
  gap: 1rem;
}
.col-span-1 {
  grid-column: span 1;
}

.col-span-2 {
  grid-column: span 2;
}
</style>