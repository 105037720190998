<template>
  <div v-if="!isMobileView" class="relative w-full h-full font-sans">
    <SidebarComponent :data="data" />
    <MenuBarComponent />
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" :data="data" :key="$route.path"></component>
      </transition>
    </router-view>
  </div>
  <div v-else>
    <MobileViewDetected />
  </div>
  <div v-if="showEditSlotDialog">
    <EditSlotComponent />
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "../../store/store.js";
import SidebarComponent from "./common/SidebarComponent.vue";
import MenuBarComponent from "./common/MenuBarComponent.vue";
import MobileViewDetected from "./common/MobileViewDetected.vue";
import EditSlotComponent from "./admin/edit/EditSlotComponent.vue";

const isMobileView = ref(false);
const store = useStore();
const showEditSlotDialog = computed(() => store.showEditSlotDialog);
const checkScreenWidth = () => {
  isMobileView.value = window.innerWidth < 1272;
};

onMounted(() => {
  checkScreenWidth();
  window.addEventListener("resize", checkScreenWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkScreenWidth);
});
defineProps(["data"]);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
