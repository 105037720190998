<template>
  <Dialog :visible="showEdit" modal class="edit-dialog">
    <template #header>
      <div class="flex justify-between w-full">
        <span class="p-dialog-title">{{ dictionary.edit_header }}</span>
        <Button icon="pi pi-times" @click="handleCloseEdit" />
      </div>
    </template>
    <form @submit.prevent="onSubmit" class="w-full custom:text-xs xl:text-base">
      <div class="flex flex-row w-full">
        <div v-if="imgUrl" class="w-1/5 mr-2">
          <img :src="imgUrl" alt="training_icon" class="preview-img" />
        </div>
        <div v-else class="img-placeholder mr-2">
          <Skeleton width="100%" height="100%" />
        </div>
        <div class="w-4/5">
          <small class="flex items-center flex-row"
            >{{ dictionary.edit_sharepoint }}
            <Button
              as="a"
              label="SharePoint Library"
              href="https://schwarzit.sharepoint.com/sites/global-lidl-leon/SiteAssets/Forms/AllItems.aspx?id=%2Fsites%2Fglobal%2Dlidl%2Dleon%2FSiteAssets%2FMapper"
              target="_blank"
              rel="noopener"
              class="m-0 pl-2 py-0"
          /></small>
          <FloatLabel>
            <InputText id="img_url" v-model="imgUrl" />
            <label for="img_url">{{ dictionary.edit_url }}</label>
          </FloatLabel>
        </div>
      </div>
      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-2">
          <FloatLabel>
            <InputText id="objective" v-model="objective" />
            <label for="objective">{{ dictionary.edit_objective }}</label>
          </FloatLabel>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <InputText id="short" v-model="shortname" />
            <label for="short">{{ dictionary.edit_shortname }}</label>
          </FloatLabel>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <Dropdown
              v-model="selectedDepartment"
              inputId="dd-department"
              :options="departments"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-department">{{ dictionary.edit_department }}</label>
          </FloatLabel>
        </div>
      </div>
      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-2">
          <FloatLabel>
            <Dropdown
              v-model="selectedDuration"
              inputId="dd-duration"
              :options="durations"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-duration"
              >{{ dictionary.edit_duration }} (min)</label
            >
          </FloatLabel>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <Dropdown
              v-model="selectedType"
              inputId="dd-type"
              :options="types"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-type">{{ dictionary.edit_type }}</label>
          </FloatLabel>
        </div>
        <div class="col-span-1">
          <FloatLabel>
            <Dropdown
              v-model="selectedElement"
              inputId="dd-elements"
              :options="trainingElements"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-elements">{{ dictionary.edit_elements }}</label>
          </FloatLabel>
        </div>
      </div>
      <div class="grid grid-cols-4 gap-4">
        <div class="col-span-2">
          <FloatLabel>
            <Dropdown
              v-model="selectedTarget"
              inputId="dd-target"
              :options="targets"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-target">{{ dictionary.edit_target }}</label>
          </FloatLabel>
        </div>
        <div class="col-span-2">
          <FloatLabel>
            <Dropdown
              v-model="selectedGroup"
              inputId="dd-experience"
              :options="groups"
              optionLabel="name"
              class="w-full"
            />
            <label for="dd-experience">{{ dictionary.edit_group }}</label>
          </FloatLabel>
        </div>
      </div>
      <div class="mt-4 flex flex-row w-full items-center">
        <span class="text-left mr-2 custom:text-sm xl:text-base">{{
          dictionary.comparator_topics
        }}</span>
        <Button
          v-if="!selectedModule"
          @click="handleAddNewTopic()"
          icon="pi pi-plus-circle"
          :label="dictionary.edit_topic_btn"
          class="m-0 p-0"
          :disabled="topics.length >= 6"
        />
      </div>
      <div class="grid grid-cols-4 gap-4" v-if="selectedModule">
        <FloatLabel
          v-for="(spec, index) in selectedModule.specification"
          :key="index"
          :class="[
            'custom:text-sm xl:text-base custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
          ]"
        >
          <InputText :id="'topic_' + index" v-model="spec.topic" />
          <label :for="'topic_' + index">{{ dictionary.edit_topic }}</label>
        </FloatLabel>
      </div>
      <div v-else class="grid grid-cols-4 gap-4">
        <FloatLabel
          v-for="(topic, index) in topics"
          :key="index"
          :class="[
            'custom:text-sm xl:text-base custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
          ]"
        >
          <InputText :id="'topic_' + index" v-model="topics[index].name" />
          <label :for="'topic_' + index">{{ dictionary.edit_topic }}</label>
        </FloatLabel>
        <small v-if="topics.length >= 6" class="p-error">{{
          dictionary.edit_topic_warn
        }}</small>
      </div>
      <div class="mt-4 flex flex-row w-full items-center">
        <span class="text-left mr-2 custom:text-sm xl:text-base">{{
          dictionary.comparator_stopics
        }}</span>
        <Button
          v-if="!selectedModule"
          @click="handleAddNewSubTopic()"
          icon="pi pi-plus-circle"
          :label="dictionary.edit_stopic_btn"
          class="m-0 p-0"
          :disabled="stopics.length >= 6"
        />
      </div>
      <div class="grid grid-cols-4 gap-4" v-if="selectedModule">
        <FloatLabel
          v-for="(spec, index) in selectedModule.specification"
          :key="index"
          :class="[
            'custom:text-sm xl:text-base custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
          ]"
        >
          <InputText :id="'stopic_' + index" v-model="spec.stopic" />
          <label :for="'stopic_' + index">{{ dictionary.edit_stopic }}</label>
        </FloatLabel>
      </div>
      <div v-else class="grid grid-cols-4 gap-4">
        <FloatLabel
          v-for="(stopic, index) in stopics"
          :key="index"
          :class="[
            'custom:text-sm xl:text-base custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
          ]"
        >
          <InputText :id="'stopic_' + index" v-model="stopics[index].name" />
          <label :for="'stopic_' + index">{{ dictionary.edit_stopic }}</label>
        </FloatLabel>
        <small v-if="stopics.length >= 6" class="p-error">{{
          dictionary.edit_stopic_warn
        }}</small>
      </div>
      <!-- TAGI -->

      <div class="mt-4 flex flex-row w-full items-center">
        <span class="text-left mr-2 custom:text-sm xl:text-base">{{
          dictionary.comparator_tags
        }}</span>
        <Button
          v-if="!selectedModule"
          @click="handleAddNewTag()"
          icon="pi pi-plus-circle"
          :label="dictionary.edit_tag_btn"
          class="m-0 p-0"
          :disabled="tags.length >= 6"
        />
      </div>
      <div class="grid grid-cols-4 gap-4" v-if="selectedModule">
        <FloatLabel
          v-for="(tag, index) in selectedModule.tag"
          :key="index"
          :class="[
            'custom:text-sm xl:text-base custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
          ]"
        >
          <InputText
            :id="'tag_' + index"
            v-model="selectedModule.tag[index]"
            class="uppercase"
          />
          <label :for="'tag_' + index">{{ dictionary.edit_tag }}</label>
        </FloatLabel>
      </div>
      <div v-else class="grid grid-cols-4 gap-4">
        <FloatLabel
          v-for="(tag, index) in tags"
          :key="index"
          :class="[
            'custom:text-sm xl:text-base custom:text-sm xl:text-base',
            index % 3 === 0 ? 'col-span-2' : 'col-span-1',
          ]"
        >
          <InputText
            :id="'tag_' + index"
            v-model="tags[index].name"
            class="uppercase"
          />
          <label :for="'tag_' + index">{{ dictionary.edit_tag }}</label>
        </FloatLabel>
        <small v-if="tags.length >= 6" class="p-error">{{
          dictionary.edit_tag_warn
        }}</small>
      </div>
      <div class="w-full flex justify-end">
        <Button
          type="submit"
          class="submit-btn"
          icon="pi pi-plus"
          :label="dictionary.login_btn"
        />
      </div>
    </form>
  </Dialog>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "../../../../store/store.js";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import Skeleton from "primevue/skeleton";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import { dictionary } from "../../../dictionary.js";

const store = useStore();
const showEdit = computed(() => store.showEditSlotDialog);
const selectedModule = ref(JSON.parse(localStorage.getItem("mod")));
const imgUrl = ref("");
const objective = ref("");
const shortname = ref("");
const selectedElement = ref("");
const selectedDepartment = ref("");
const selectedDuration = ref("");
const selectedType = ref("");
const selectedTarget = ref("");
const selectedGroup = ref("");
const topic = ref("");
const topics = ref([{ name: "" }, { name: "" }, { name: "" }]);
const stopic = ref("");
const stopics = ref([{ name: "" }, { name: "" }, { name: "" }]);
const tag = ref("");
const tags = ref([{ name: "" }, { name: "" }, { name: "" }]);

const trainingElements = [
  { name: "Course" },
  { name: "Course + Test" },
  { name: "Course + Test + FL" },
];
const departments = [
  { name: "Sales" },
  { name: "Logistics" },
  { name: "HR" },
  { name: "Others" },
];
const durations = [
  { name: 5 },
  { name: 10 },
  { name: 15 },
  { name: 20 },
  { name: 25 },
  { name: 30 },
];
const types = [
  { name: "MA" },
  { name: "FtF" },
  { name: "Nugget" },
  { name: "Other" },
];

const targets = [{ name: "Employee" }, { name: "Manager" }];
const groups = [{ name: "All" }, { name: "New" }, { name: "Promotion" }];

const handleCloseEdit = () => {
  localStorage.removeItem("mod");
  store.$patch({ showEditSlotDialog: false });
};

onMounted(() => {
  if (selectedModule.value) {
    imgUrl.value = selectedModule.value.url;
    objective.value = selectedModule.value.specification[0].objective;
    shortname.value = selectedModule.value.short;
    selectedDepartment.value = { name: selectedModule.value.department };
    selectedDuration.value = { name: selectedModule.value.duration };
    selectedType.value = { name: selectedModule.value.type };
    selectedElement.value = { name: selectedModule.value.element };
    selectedTarget.value = { name: selectedModule.value.target };
    selectedGroup.value = { name: selectedModule.value.employee };
  }
});

const handleAddNewTopic = () => {
  topics.value.push({ name: topic.value });
  topic.value = "";
};

const handleAddNewSubTopic = () => {
  stopics.value.push({ name: stopic.value });
  stopic.value = "";
};

const handleAddNewTag = () => {
  tags.value.push({ name: tag.value });
  tag.value = "";
};

const onSubmit = async (event) => {
  event.preventDefault();
};
</script>

<style scoped>
.img-placeholder {
  width: 180px;
  height: 100px;
}

form {
  color: #c4e9ff;
}
.uppercase {
  text-transform: uppercase;
}
</style>