<template>
  <div class="container flex flex-col custom:ml-72 xl:ml-96">
    <div class="mb-2 flex flex-row justify-start items-center">
      <span class="mr-2">{{ dictionary.library_filters }}</span>
      <Chip
        v-if="selectedType"
        class="filter-tag mr-2"
        :label="selectedType.name"
        removable
        @remove="removeFilter('type')"
      />
      <Chip
        v-if="selectedTopic"
        class="filter-tag mr-2"
        :label="selectedTopic.name"
        removable
        @remove="removeFilter('topic')"
      />
      <Chip
        v-if="selectedTarget"
        class="filter-tag mr-2"
        :label="selectedTarget.name"
        removable
        @remove="removeFilter('target')"
      />
      <Chip
        v-if="selectedTags"
        class="filter-tag mr-2"
        :label="selectedTags.name"
        removable
        @remove="removeFilter('tags')"
      />
      <Chip
        v-if="selectedDepartment"
        class="filter-tag mr-2"
        :label="selectedDepartment"
        removable
        @remove="removeFilter('department')"
      />
      <Chip
        v-if="selectedDuration"
        class="filter-tag mr-2"
        :label="selectedDuration.name + ' min'"
        removable
        @remove="removeFilter('duration')"
      />
      <p
        v-else-if="
          !selectedType &&
          !selectedTopic &&
          !selectedTarget &&
          !selectedTags &&
          !selectedDepartment &&
          !selectedDuration
        "
      >
        {{ dictionary.none }}
      </p>
    </div>
    <ModulesTableComponent :data="modules" />
  </div>
</template>

<script setup>
import { ref, watch, defineProps, computed, onUnmounted } from "vue";
import { useStore } from "../../../store/store.js";
import ModulesTableComponent from "../dashboard/table/ModulesTableComponent.vue";
import Chip from "primevue/chip";
import { dictionary } from "../../dictionary.js";
const store = useStore();
const props = defineProps({
  data: Array,
});
const modules = ref(props.data);

const selectedType = computed(() => store.selectedType);
const selectedTopic = computed(() => store.selectedTopic);
const selectedTarget = computed(() => store.selectedTarget);
const selectedTags = computed(() => store.selectedTags);
const selectedDepartment = computed(() => store.selectedDepartment);
const selectedDuration = computed(() => store.selectedDuration);

const filterModules = () => {
  let filteredData = props.data;
  if (selectedDepartment.value) {
    filteredData = filteredData.filter(
      (item) => item.department === selectedDepartment.value
    );
  }
  if (selectedType.value) {
    filteredData = filteredData.filter(
      (item) => item.type === selectedType.value.name
    );
  }
  if (selectedTopic.value) {
    filteredData = filteredData.filter((item) =>
      item.specification.some((spec) => spec.topic === selectedTopic.value.name)
    );
  }
  if (selectedTarget.value) {
    filteredData = filteredData.filter(
      (item) => item.target === selectedTarget.value.name
    );
  }
  if (selectedTags.value) {
    filteredData = filteredData.filter((item) =>
      item.tag.includes(selectedTags.value.name)
    );
  }
  if (selectedDuration.value) {
    filteredData = filteredData.filter(
      (item) => item.duration === selectedDuration.value.name
    );
  }

  modules.value = filteredData;
};

const removeFilter = (filter) => {
  switch (filter) {
    case "type":
      store.selectedType = null;
      break;
    case "topic":
      store.selectedTopic = null;
      break;
    case "target":
      store.selectedTarget = null;
      break;
    case "tags":
      store.selectedTags = null;
      break;
    case "department":
      store.selectedDepartment = null;
      break;
    case "duration":
      store.selectedDuration = null;
      break;
  }
};

onUnmounted(() => {
  store.selectedType = null;
  store.selectedTopic = null;
  store.selectedTarget = null;
  store.selectedTags = null;
  store.selectedDepartment = null;
  store.selectedDuration = null;
});

watch(
  [
    selectedDepartment,
    selectedType,
    selectedTopic,
    selectedTarget,
    selectedTags,
    selectedDuration,
  ],
  filterModules
);
</script>
