<template>
  <div class="container flex flex-col custom:ml-72 xl:ml-96">
    <div class="text-2xl flex flex-row mb-4">
      <img :src="require('../../assets/dashboard.png')" alt="dashboard" class="w-full"/>
    </div>
    <div class="row">
      <ModulesTableComponent :data="data" />
    </div>
  </div>
</template>

<script setup>
import ModulesTableComponent from "./table/ModulesTableComponent.vue";
import { ref, onMounted, defineProps } from "vue";

const props = defineProps(["data"]);
const moduleTypes = ref({
  sales: 0,
  logistics: 0,
  hr: 0,
  others: 0,
});

onMounted(() => {
  countModuleTypes();
});

const countModuleTypes = () => {
  props.data.forEach((item) => {
    if (moduleTypes.value[item.department.toLowerCase()] !== undefined) {
      moduleTypes.value[item.department.toLowerCase()]++;
    }
  });
};
</script>

<style scoped>
.row {
  width: 100%;
}

div p {
  opacity: 0.8;
}
.card-container {
  color: rgb(255, 255, 255);
  width: calc(100% / 4);
}
.p-card {
  background-color: transparent !important;
}
</style>
