<template>
  <div class="container custom:ml-72 xl:ml-96 relative">
    <div class="info-wrapper flex flex-column items-center w-full mb-4">
      <i class="pi pi-info-circle mr-4"></i>
      <p>{{ dictionary.detailed_info }}</p>
    </div>
    <div class="switch-wrapper">
      <div class="flex items-center custom:mb-2 xl:mb-3">
        <InputSwitch v-model="topicsChecked" class="switch-topics" />
        <span class="custom:ml-2 xl:ml-4 custom:text-sm xl: text-base">{{
          dictionary.detailed_map_topics
        }}</span>
      </div>
      <div class="flex items-center custom:mb-2 xl:mb-3">
        <InputSwitch v-model="basicChecked" class="switch-basic-info" />
        <span class="custom:ml-2 xl:ml-4 custom:text-sm xl: text-base">{{
          dictionary.detailed_map_info
        }}</span>
      </div>
      <div class="flex items-center custom:mb-2 xl:mb-3">
        <InputSwitch v-model="stopicsChecked" class="switch-subtopics" />
        <span class="custom:ml-2 xl:ml-4 custom:text-sm xl: text-base">{{
          dictionary.detailed_map_subtopics
        }}</span>
      </div>
      <div class="flex items-center custom:mb-2 xl:mb-3">
        <InputSwitch v-model="keyChecked" class="switch-keywords" />
        <span class="custom:ml-2 xl:ml-4 custom:text-sm xl: text-base">{{
          dictionary.detailed_map_tags
        }}</span>
      </div>
    </div>
    <VueFlow
      :nodes="nodes"
      :edges="edges"
      class="basic-flow"
      ref="vueFlowRef"
      :default-viewport="{ zoom: 1.2 }"
      :min-zoom="0.8"
      :max-zoom="1.2"
      @pane-ready="onPaneReady"
    >
    </VueFlow>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { VueFlow, useVueFlow } from "@vue-flow/core";
import InputSwitch from "primevue/inputswitch";
import { dictionary } from "../../dictionary.js";

const { onInit, onConnect, addEdges } = useVueFlow();
const mod = JSON.parse(localStorage.getItem("mod"));
const basicChecked = ref(true);
const keyChecked = ref(true);
const topicsChecked = ref(true);
const stopicsChecked = ref(true);
const vueFlowRef = ref(null);
const windowWidth = ref(window.innerWidth);
let vueFlowInstance = null;

const topics = mod.specification.map((s, i, arr) => {
  const centerX = -200;
  const centerY = -300;
  const radiusX = -150;
  const radiusY = -100;
  const angle =
    arr.length > 1 ? Math.PI / 2 - Math.PI * (i / (arr.length - 1)) : 0;
  const x = centerX + radiusX * Math.cos(angle);
  const y = centerY + radiusY * Math.sin(angle);
  return {
    id: `${mod.short.toLowerCase()}_${i + 1}_topic`,
    label: s.topic,
    class: "topics vue-flow-topics-node",
    position: { x, y },
  };
});

let nodeIndex = 1;
let angleIndex = 0;
const totalElements = mod.specification.reduce(
  (total, s) => total + s.stopic.length,
  0
);
const stopics = mod.specification
  .map((s) => {
    const result = [];
    s.stopic.map((st) => {
      const centerX = -250;
      const centerY = 50;
      const radiusX = -220;
      const radiusY = 120;
      const angle = (2 * Math.PI * angleIndex) / totalElements;
      const x = centerX + radiusX * Math.cos(angle);
      const y = centerY + radiusY * Math.sin(angle);
      const elem = {
        id: `${mod.short.toLowerCase()}_${nodeIndex}_stopic`,
        label: st,
        class: "stopics vue-flow-stopics-node",
        position: { x, y },
      };
      result.push(elem);
      nodeIndex++;
      angleIndex++;
    });
    return result;
  })
  .flat();

let uniqueStopics = [];
let labels = {};

for (let i = 0; i < stopics.length; i++) {
  if (!labels[stopics[i].label]) {
    labels[stopics[i].label] = true;
    uniqueStopics.push(stopics[i]);
  }
}

const tags = mod.tag.map((t, i, arr) => {
  const centerX = 250;
  const centerY = 0;
  const radiusX = 200;
  const radiusY = 100;
  const angle = Math.PI / 2 - Math.PI * (i / (arr.length - 1));
  const x = centerX + radiusX * Math.cos(angle);
  const y = centerY + radiusY * Math.sin(angle);

  return {
    id: `${mod.short.toLowerCase()}_${i + 1}_tag`,
    label: t,
    class: "tags vue-flow-tags-node",
    position: { x, y },
  };
});

onInit((vueFlowInstance) => {
  vueFlowInstance.fitView();
});

onConnect((connection) => {
  addEdges(connection);
});

const nodes = ref([
  //main nodes:
  {
    id: mod.short.toLowerCase() + "_1",
    class: "vue-flow-module-name",
    label: mod.short,
    position: { x: 100, y: -150 },
  },
  {
    id: mod.short.toLowerCase() + "_2",
    label: dictionary.detailed_map_topics,
    class: "topics vue-flow-topics",
    position: { x: -50, y: -300 },
  },
  {
    id: mod.short.toLowerCase() + "_3",
    label: dictionary.detailed_map_info,
    class: "basic-info vue-flow-basic-info",
    position: { x: 300, y: -300 },
  },
  {
    id: mod.short.toLowerCase() + "_4",
    label: dictionary.detailed_map_tags,
    class: "tags vue-flow-tags",
    position: { x: 300, y: 0 },
  },
  {
    id: mod.short.toLowerCase() + "_5",
    label: dictionary.detailed_map_subtopics,
    class: "stopics vue-flow-stopics",
    position: { x: -250, y: 50 },
  },
  //basic info nodes:
  {
    id: mod.short.toLowerCase() + "_1_info",
    label: mod.employee + dictionary.detailed_map_employees,
    class: "basic-info vue-flow-basic-info-node",
    position: { x: 150, y: -300 },
  },
  {
    id: mod.short.toLowerCase() + "_2_info",
    label: dictionary.detailed_map_duration + mod.duration,
    class: "basic-info vue-flow-basic-info-node",
    position: { x: 200, y: -400 },
  },
  {
    id: mod.short.toLowerCase() + "_3_info",
    label: mod.type,
    class: "basic-info vue-flow-basic-info-node",
    position: { x: 400, y: -380 },
  },
  {
    id: mod.short.toLowerCase() + "_4_info",
    label: mod.element,
    class: "basic-info vue-flow-basic-info-node",
    position: { x: 450, y: -250 },
  },
  {
    id: mod.short.toLowerCase() + "_5_info",
    label: mod.department,
    class: "basic-info vue-flow-basic-info-node",
    position: { x: 320, y: -200 },
  },
]);

nodes.value = nodes.value.concat(topics);
nodes.value = nodes.value.concat(uniqueStopics);
nodes.value = nodes.value.concat(tags);

const sourceExists = (source) => {
  return nodes.value.some(
    (node) => node.id === mod.short.toLowerCase() + "_" + source
  );
};

const targetExists = (target) => {
  return nodes.value.some(
    (node) => node.id === mod.short.toLowerCase() + "_" + target
  );
};

const createEdge = (id, source, target, edgeClass) => {
  if (sourceExists(source) && targetExists(target)) {
    return {
      id: mod.short.toLowerCase() + "_" + id,
      source: mod.short.toLowerCase() + "_" + source,
      target: mod.short.toLowerCase() + "_" + target,
      class: edgeClass,
    };
  }
  return null;
};

const edges = ref(
  [
    //main edges:
    createEdge("e1-2", "2", "1", "topics vue-flow-module-name-edge"),
    createEdge("e1-3", "3", "1", "basic-info vue-flow-module-name-edge"),
    createEdge("e1-4", "1", "4", "tags vue-flow-module-name-edge"),
    createEdge("e1-5", "1", "5", "stopics vue-flow-module-name-edge"),
    //basic info edges:
    createEdge(
      "e3-1_info",
      "1_info",
      "3",
      "basic-info vue-flow-basic-info-edge"
    ),
    createEdge(
      "e3-2_info",
      "2_info",
      "3",
      "basic-info vue-flow-basic-info-edge"
    ),
    createEdge(
      "e3-3_info",
      "3_info",
      "3",
      "basic-info vue-flow-basic-info-edge"
    ),
    createEdge(
      "e3-4_info",
      "3",
      "4_info",
      "basic-info vue-flow-basic-info-edge"
    ),
    createEdge(
      "e3-5_info",
      "3",
      "5_info",
      "basic-info vue-flow-basic-info-edge"
    ),
    //tags edges:
    createEdge("e4-1_tag", "4", "1_tag", "tags vue-flow-tags-edge"),
    createEdge("e4-2_tag", "4", "2_tag", "tags vue-flow-tags-edge"),
    createEdge("e4-3_tag", "4", "3_tag", "tags vue-flow-tags-edge"),
    createEdge("e4-4_tag", "4_tag", "4", "tags vue-flow-tags-edge"),
    createEdge("e4-5_tag", "5_tag", "4", "tags vue-flow-tags-edge"),
    createEdge("e4-6_tag", "6_tag", "4", "tags vue-flow-tags-edge"),
    //topic edges:
    createEdge("e2-1_topic", "1_topic", "2", "topics vue-flow-topics-edge"),
    createEdge("e2-2_topic", "2_topic", "2", "topics vue-flow-topics-edge"),
    createEdge("e2-3_topic", "2", "3_topic", "topics vue-flow-topics-edge"),
    createEdge("e2-4_topic", "2", "4_topic", "topics vue-flow-topics-edge"),
    //subtopic edges:
    createEdge("e5-1_stopic", "1_stopic", "5", "stopics vue-flow-stopics-edge"),
    createEdge("e5-2_stopic", "5", "2_stopic", "stopics vue-flow-stopics-edge"),
    createEdge("e5-3_stopic", "5", "3_stopic", "stopics vue-flow-stopics-edge"),
    createEdge("e5-4_stopic", "5", "4_stopic", "stopics vue-flow-stopics-edge"),
    createEdge("e5-5_stopic", "5", "5_stopic", "stopics vue-flow-stopics-edge"),
    createEdge("e5-6_stopic", "5", "6_stopic", "stopics vue-flow-stopics-edge"),
    createEdge("e5-7_stopic", "7_stopic", "5", "stopics vue-flow-stopics-edge"),
    createEdge("e5-8_stopic", "8_stopic", "5", "stopics vue-flow-stopics-edge"),
    createEdge("e5-9_stopic", "9_stopic", "5", "stopics vue-flow-stopics-edge"),
    createEdge(
      "e5-10_stopic",
      "10_stopic",
      "5",
      "stopics vue-flow-stopics-edge"
    ),
    createEdge(
      "e5-11_stopic",
      "11_stopic",
      "5",
      "stopics vue-flow-stopics-edge"
    ),
    createEdge(
      "e5-12_stopic",
      "12_stopic",
      "5",
      "stopics vue-flow-stopics-edge"
    ),
  ].filter((edge) => edge !== null)
);

watch(
  () => basicChecked.value,
  (newValue) => {
    const elements = document.getElementsByClassName("basic-info");

    for (let i = 0; i < elements.length; i++) {
      if (newValue) {
        elements[i].classList.remove("vue-flow-basic-hide");
        elements[i].classList.add("vue-flow-basic-show");
      } else {
        elements[i].classList.remove("vue-flow-basic-show");
        elements[i].classList.add("vue-flow-basic-hide");
      }
    }
  }
);

watch(
  () => topicsChecked.value,
  (newValue) => {
    const elements = document.getElementsByClassName("topics");

    for (let i = 0; i < elements.length; i++) {
      if (newValue) {
        elements[i].classList.remove("vue-flow-topics-hide");
        elements[i].classList.add("vue-flow-topics-show");
      } else {
        elements[i].classList.remove("vue-flow-topics-show");
        elements[i].classList.add("vue-flow-topics-hide");
      }
    }
  }
);

watch(
  () => stopicsChecked.value,
  (newValue) => {
    const elements = document.getElementsByClassName("stopics");

    for (let i = 0; i < elements.length; i++) {
      if (newValue) {
        elements[i].classList.remove("vue-flow-stopics-hide");
        elements[i].classList.add("vue-flow-stopics-show");
      } else {
        elements[i].classList.remove("vue-flow-stopics-show");
        elements[i].classList.add("vue-flow-stopics-hide");
      }
    }
  }
);

watch(
  () => keyChecked.value,
  (newValue) => {
    const elements = document.getElementsByClassName("tags");

    for (let i = 0; i < elements.length; i++) {
      if (newValue) {
        elements[i].classList.remove("vue-flow-tags-hide");
        elements[i].classList.add("vue-flow-tags-show");
      } else {
        elements[i].classList.remove("vue-flow-tags-show");
        elements[i].classList.add("vue-flow-tags-hide");
      }
    }
  }
);

const adjustZoom = () => {
  if (vueFlowInstance) {
    if (windowWidth.value <= 1272) {
      vueFlowInstance.setViewport({ x: 400, y: 350, zoom: 0.8 });
    } else {
      vueFlowInstance.setViewport({ x: 600, y: 525, zoom: 1.2 });
    }
  }
};

const onPaneReady = (instance) => {
  vueFlowInstance = instance;
  adjustZoom();
};

watch(windowWidth, adjustZoom);

const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", updateWindowWidth);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateWindowWidth);
});
</script>

<style scoped>
@import "@vue-flow/core/dist/style.css";
@import "@vue-flow/core/dist/theme-default.css";

.switch-wrapper {
  width: auto;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
}

.row {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}
.col {
  width: 50%;
  padding: 1rem;
  height: 100%;
}
</style>
