<template>
  <div class="container custom:ml-72 xl:ml-96">
    <div class="button-wrapper custom:pb-4 xl:pb-8">
      <div v-if="selectedModule1" class="col custom:mr-2 xl:mr-4 rounded">
        <ModuleSlot1
          :mod1="selectedModule1"
          :mod2="selectedModule2"
          :slot1="availableSlot1"
          @update:mod1="selectedModule1 = $event"
          @update:slot1="availableSlot1 = $event"
        />
      </div>
      <Button
        v-else
        :label="dictionary.comparator_btn_compare"
        icon="pi pi-plus"
        @click="showDialog = true"
      />
      <div v-if="selectedModule2" class="col custom:ml-2 xl:ml-4 rounded">
        <ModuleSlot2
          :mod1="selectedModule1"
          :mod2="selectedModule2"
          :slot2="availableSlot2"
          @update:mod2="selectedModule2 = $event"
          @update:slot2="availableSlot2 = $event"
        />
      </div>
      <Button
        v-else
        :label="dictionary.comparator_btn_compare"
        icon="pi pi-plus"
        @click="showDialog = true"
      />
    </div>
    <Dialog
      v-model:visible="showDialog"
      header="Select training to compare"
      class="w-1/2 custom:ml-60 xl:ml-72"
    >
      <div class="dialog-content">
        <IconField>
          <InputIcon class="pi pi-search" />
          <InputText
            v-model="searchValue"
            type="text"
            class="w-full p-2 mr-2 bg-transparent"
          />
        </IconField>
        <div class="flex items-center my-2">
          <i class="pi pi-info-circle mr-2"></i>
          <small>{{ dictionary.comparator_search_info }}</small>
        </div>
        <Divider />
        <ol>
          <li
            v-for="(mod, index) in filteredModules"
            :key="index"
            class="flex flex-col mb-2"
          >
            <div class="flex justify-start items-center">
              <div class="w-1/5">
                <img
                  :src="mod.url"
                  :alt="mod.short"
                  class="w-full border-round custom:mt-6 xl:mt-0"
                />
              </div>
              <div class="flex-1 mx-4 flex flex-col">
                <span
                  id="dep"
                  :class="
                    mod.department.toLowerCase() +
                    '-bg p-1 rounded-lg custom:text-xs xl:text-base'
                  "
                >
                  {{ mod.department }}
                </span>
                <span
                  class="font-medium text-sm custom:text-xs xl:text-base"
                  >{{ dictionary.dataview_item2 + mod.duration }} min</span
                >
                <span
                  :class="
                    mod.department.toLowerCase() +
                    '-txt custom:text-base xl:text-xl'
                  "
                  >{{ mod.short }}</span
                >
                <div>
                  <span class="custom:text-xs xl:text-sm">{{
                    dictionary.dataview_item3
                  }}</span>
                </div>
                <span class="text-left custom:text-xs xl:text-sm">{{
                  mod.specification[0].objective
                }}</span>
              </div>
              <Button
                icon="pi pi-plus"
                class="dataview-btn custom:p-1 custom:text-xs custom:h-8 custom:mt-1 custom:mr-3 xl:p-2 xl:mr-4 xl:text-base xl:h-auto xl:mt-0 rounded w-1/5"
                :label="dictionary.comparator_btn_select"
                @click="handleModuleSelection(mod)"
                :disabled="
                  selectedModule1 ? mod.short === selectedModule1.short : false
                "
              />
            </div>
            <Divider />
          </li>
        </ol>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, computed } from "vue";
import ModuleSlot1 from "./slots/ModuleSlot1.vue";
import ModuleSlot2 from "./slots/ModuleSlot2.vue";
import { dictionary } from "../../dictionary.js";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";

const props = defineProps({
  data: Array,
});
const modules = ref(props.data);
const selectedModule1 = ref(JSON.parse(localStorage.getItem("mod")));
const availableSlot1 = ref(true);
const selectedModule2 = ref(null);
const availableSlot2 = ref(true);
const showDialog = ref(true);
const searchValue = ref("");

const handleModuleSelection = (mod) => {
  showDialog.value = false;
  searchValue.value = "";
  if (availableSlot1.value && selectedModule1.value === null) {
    availableSlot1.value = false;
    selectedModule1.value = mod;
  } else {
    availableSlot2.value = false;
    selectedModule2.value = mod;
  }
};

const filteredModules = computed(() => {
  return modules.value
    .filter((mod) => {
      return (
        mod.short.toLowerCase().includes(searchValue.value.toLowerCase()) ||
        mod.department
          .toLowerCase()
          .includes(searchValue.value.toLowerCase()) ||
        mod.duration.toString().includes(searchValue.value)
      );
    })
    .sort((a, b) => a.short.localeCompare(b.short));
});

watch([selectedModule1, selectedModule2], ([newMod1, newMod2]) => {
  if (newMod1 && newMod2) {
    selectedModule1.value = newMod1;
    selectedModule2.value = newMod2;
  }
});
</script>

<style scoped>
.container {
  overflow-y: scroll;
}
.button-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.col {
  width: 50%;
  height: auto;
  min-height: 50vh;
  background-color: #18212d;
}
.button-wrapper button {
  width: 45%;
  height: 50vh;
  padding: 1rem;
  transition: ease-in-out;
  transition-duration: 0.3s;
  color: #c4e9ff;
  background-color: transparent;
  border: 1px solid #c4e9ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper button:hover {
  background-color: transparent;
  color: rgba(254, 196, 30, 1);
  border: 1px solid rgba(254, 196, 30, 1);
}

#dep {
  width: auto !important;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-content {
  width: 100%;
  height: auto;
  min-height: 850px !important;
}
</style>
